/* loadingAnim.css */
.loading-animation-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle, #1a1a2e 0%, #16213e 100%);
    z-index: 9999;
    overflow: hidden;
}

.security-circle {
    position: absolute;
    width: 800px;
    height: 800px;
    border: 2px solid rgba(233, 69, 96, 0.1);
    border-radius: 50%;
    animation: pulse 2s ease-out infinite;
}

.loading-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
}

.loading-spinner-large {
    width: 80px;
    height: 80px;
    border: 8px solid rgba(233, 69, 96, 0.3);
    border-top: 8px solid #e94560;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
}

.loading-text {
    color: #e94560;
    font-family: 'Cinzel', serif;
    font-size: 24px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin: 20px 0;
    opacity: 0.8;
    animation: fadeInOut 2s ease-in-out infinite;
}

.loading-dots {
    display: inline-block;
    min-width: 30px;
    text-align: left;
}

.progress-bar {
    width: 300px;
    height: 4px;
    background: rgba(233, 69, 96, 0.2);
    border-radius: 2px;
    overflow: hidden;
    margin-top: 165px;
}

.progress-fill {
    width: 100%;
    height: 100%;
    background: #e94560;
    animation: progressAnimation 3s ease-in-out infinite;
    transform-origin: left;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        opacity: 0.5;
    }

    50% {
        transform: scale(1);
        opacity: 0.2;
    }

    100% {
        transform: scale(1.05);
        opacity: 0;
    }
}

@keyframes progressAnimation {
    0% {
        transform: translateX(-100%);
    }

    50% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

/* Add multiple security circles with different sizes and animations */
.loading-animation-container::before,
.loading-animation-container::after {
    content: '';
    position: absolute;
    border: 1px solid rgba(233, 69, 96, 0.05);
    border-radius: 50%;
}

.loading-animation-container::before {
    width: 600px;
    height: 600px;
    animation: pulse 2s ease-out infinite 0.5s;
}

.loading-animation-container::after {
    width: 400px;
    height: 400px;
    animation: pulse 2s ease-out infinite 1s;
}