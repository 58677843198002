.cookie-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cookie-policy-modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px; /* Slightly larger max-width for better readability */
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08); /* Soft box shadow for depth */
  display: flex;
  flex-direction: column;
  overflow: hidden; /* Hide overflow for border-radius */
  transform: scale(0.9); /* Start slightly smaller for animation */
  animation: modalAppear 0.3s forwards; /* Smooth scaling animation */
}

@keyframes modalAppear {
  to {
    transform: scale(1); /* Scale to normal size */
    opacity: 1; /* Fade in from 0 to 1 opacity */
  }
}

.cookie-policy-content {
  margin: 20px 0;
  overflow-y: auto;
  max-height: 300px; /* Limit height with overflow for very long content */
}

.more-info-link {
  color: #007bff;
  text-decoration: none;
  transition: color 0.2s; /* Smooth transition for color change */
}

.more-info-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.accept-button,
.close-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition:
    background-color 0.2s,
    transform 0.2s;
}

.accept-button:hover,
.close-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px); /* Slight lift effect */
}

.close-button {
  background: none;
  color: #333;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
}

.close-button:hover {
  background-color: transparent;
  color: #000;
}

.accept-button {
  width: 8rem;
  align-self: center;
}

.dropdown-button {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  cursor: pointer;
  padding: 5px;
  margin-left: 10px;
  border-radius: 5px;
  transition: background-color 0.2s;
}

.dropdown-button:hover {
  background-color: #e0e0e0;
}

.dropdown-content p {
  border-bottom: 1px solid #ddd; /* Fine line for separation */
  padding-bottom: 5px; /* Spacing below text */
}

.dropdown-content p:last-child {
  border-bottom: none; /* No border for the last item */
}
