.profile-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    background: rgba(74, 60, 194, 0.2);
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    color: #ffffff;
    width: 90%;
    max-width: 800px;
    margin: 40px auto;
    position: relative;
    overflow: hidden;
}

.profile-title-container {
    margin-bottom: 30px;
    text-align: center;
}

.profile-title-container h2 {
    font-size: 2.5rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.user-information-container,
.account-info-container,
.players-container {
    width: 100%;
    margin-bottom: 30px;
    padding: 15px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 12px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    min-height: 100px;
}

.user-information-container:hover,
.account-info-container:hover,
.players-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.4);
}

.email-activation {
    display: flex;
    margin-top: 15px;
    flex-direction: column;
    gap: 1rem;
}

.send-act-codebtn {
    width: 10rem;
    height: 3rem;
    align-self: center;

    background: rgb(216, 39, 39);
    border-radius: 50%;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

.email-activation button:hover {
    background-color: #7bc000;
}

.email-activation button:disabled,
.profile-logout-button:disabled {
    background-color: #999;
    cursor: not-allowed;
    animation: none;
}


.submit-code-container {
    align-self: center;
}

.email-activation input {
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
}

.email-activation button {
    padding: 10px 20px;
    background-color: #5e9900;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* position: relative; */
    overflow: hidden;
}


.email-activation button .button-text,
.profile-logout-button .button-text {
    transition: opacity 0.3s ease;
}

.email-activation button.loading .button-text,
.profile-logout-button.loading .button-text {
    opacity: 0;
}

.email-activation button .loading-text,
.loading-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.email-activation button.loading .loading-text,
.profile-logout-button.loading .loading-text {
    opacity: 1;
}

.email-activation button .loading-text::after,
.profile-logout-button .loading-text::after {
    content: '...';
    animation: ellipsis 1.5s infinite;
}

@keyframes ellipsis {
    0% {
        content: '.';
    }

    33% {
        content: '..';
    }

    66% {
        content: '...';
    }
}

.success-message {
    color: #5e9900;
    font-weight: bold;
}

.error-message {
    color: #ff4b4b;
    font-weight: bold;
    text-align: center;
}

.activation-status {
    color: #5e9900;
    font-weight: bold;
}

.players-container {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.char-title {
    align-self: center;
    font-size: 2rem;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.charcaters-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.player-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
    width: 300px;
}

.player-card:hover {
    transform: scale(1.05);
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.4);
}

.player-card img {
    max-width: 120px;
    margin-bottom: 15px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}



.profile-logout-button:hover {
    background-color: #ff0000;
}

.fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
}

.fade-in.loaded {
    opacity: 1;
    transform: translateY(0);
}

.section-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.refresh-button {
    /* position: absolute; */
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    /* Ensure button doesn't resize */
    text-align: center;
}

.refresh-button:hover {
    background-color: #45a049;
}

.refresh-button.disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.refresh-button.disabled:hover {
    background-color: #cccccc;
}

.char-img {
    padding: 0.3rem;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    30% {
        transform: scale(0.98);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
        transform: scale(0.98);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.nav-link {
    position: relative;
}

.profile-control-btns {
    display: flex;
    justify-content: space-between;
    gap: 0.4rem;
    width: 100%;
}

.profile-logout-button,
.refresh-button {
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    overflow: hidden;
    max-width: 5rem;
}

.profile-logout-button {
    background-color: #9b1717;
    order: 1;
}

.refresh-button {
    background-color: #5e9900;
    order: 2;
}