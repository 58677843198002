/* WheelAdmin.css */
.admin-controls-container {
    margin-top: 2rem;
    background: rgba(74, 60, 194, 0.1);
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 0 20px rgba(0, 0, 255, 0.2);
    overflow: hidden;
}

.admin-title {
    font-family: 'Space Grotesk', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    color: #4a3cc2;
    text-align: center;
    margin-bottom: 1rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.3s ease;
    padding: 0.5rem;
    font-weight: bold;
}

.admin-title:hover {
    color: #6a5ce2;
}

.arrow-container {
    margin-left: 10px;
    display: inline-block;
}

.arrow {
    transition: transform 0.3s ease;
}

.arrow.up {
    transform: rotate(180deg);
}

.admin-controls {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 1rem;
    overflow: hidden;
}

.prize-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.prize-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    background: rgba(255, 255, 255, 0.1);
    padding: 0.5rem;
    border-radius: 5px;
}

.prize-item span {
    flex: 1;
}

.prize-thumbnail {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 5px;
}

.prize-item svg {
    cursor: pointer;
    transition: color 0.3s ease;
}

.prize-item svg:hover {
    color: #4a3cc2;
}

.add-prize {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.add-prize input {
    flex: 1;
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
}

.add-prize button {
    background: #4a3cc2;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-prize button:hover {
    background: #6a5ce2;
}



.search-bar {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 0.5rem;
    margin-bottom: 1rem;
}

.search-bar input {
    flex: 1;
    background: none;
    border: none;
    color: #ffffff;
    margin-left: 0.5rem;
    font-size: 1rem;
}

.search-bar input::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.search-bar svg {
    color: rgba(255, 255, 255, 0.6);
}

/* WheelAdmin.css */
/* ... (keep all existing styles) ... */

/* New styles for edit form */
.edit-form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    margin-top: 1rem;
    background: rgba(255, 255, 255, 0.05);
    padding: 1rem;
    border-radius: 5px;
}

.edit-form input {
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    font-size: 0.9rem;
}

.edit-form input::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.edit-form button {
    background: #4a3cc2;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.9rem;
    font-weight: bold;
}

.edit-form button:hover {
    background: #6a5ce2;
}

.edit-form button:last-child {
    background: #c23c3c;
}

.edit-form button:last-child:hover {
    background: #e25c5c;
}

.prize-actions {
    display: flex;
    gap: 0.5rem;
}

.prize-actions svg {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.7);
    transition: color 0.3s ease;
}

.prize-actions svg:hover {
    color: #ffffff;
}

.edit-form label {
    font-size: 0.9rem;
    color: #ffffff;
    margin-bottom: 0.2rem;
    font-weight: bold;
}

.edit-form input {
    margin-bottom: 0.5rem;
}

.add-prize {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1rem;
}

.add-prize .input-group {
    flex: 1;
    min-width: 200px;
    display: flex;
    flex-direction: column;
}

.add-prize label {
    font-size: 0.9rem;
    color: #ffffff;
    margin-bottom: 0.2rem;
    font-weight: bold;
}

.add-prize input {
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    color: #ffffff;
}

.add-prize button {
    align-self: flex-end;
    background: #4a3cc2;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: auto;
}

.add-prize button:hover {
    background: #6a5ce2;
}