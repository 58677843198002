@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');

body {
    font-family: 'Nunito', sans-serif;
}

.download-client-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
    padding: 20px;
    border: 2px solid #0077FF;
    border-radius: 10px;
    width: 60%;
    margin: 0 auto;
    gap: 3rem;
    padding-bottom: 34rem;
    padding-top: 10rem;
    padding-left: 10rem;
    padding-right: 10rem;
    margin-top: 5rem;
    background: rgba(88, 121, 67, 0.16);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.1px);
    -webkit-backdrop-filter: blur(7.1px);
    border: 1px solid rgba(88, 121, 67, 0.87);
    gap: 10rem;
}

.download-content-container {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

.download-header {
    color: #0077FF;
    margin-bottom: 40px;
    position: relative;
    display: inline-block;
    padding-bottom: 10px;
    font-size: 3rem;
}

.download-header::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 6px;
    background-color: #0077FF;
    border-radius: 3px;
}

.download-options {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.download-button {
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #0077FF;
    color: white;
    font-size: 18px;
    line-height: 60px;
    margin: 0 20px;
    transition: background-color 0.4s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.source-info {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: bold;
    background: linear-gradient(90deg, #0077FF, #df12ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
    text-align: center;
}

.download-button:hover {
    background-color: #005bb5;
}

.download-button.disabled {
    background-color: #cccccc;
    cursor: not-allowed;
    box-shadow: 0px 4px 8px rgba(229, 4, 4, 0.1);
}

#btn-download1,
#btn-download2,
#btn-download3 {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    transition: transform 0.4s ease;
}

#btn-download1:hover,
#btn-download2:hover,
#btn-download3:hover {
    transform: scale(1.1);
}

#btn-download1 svg,
#btn-download2 svg,
#btn-download3 svg {
    fill: none;
    transform: translate3d(0, 0, 0);
}

#btn-download1 polyline,
#btn-download1 path,
#btn-download2 polyline,
#btn-download2 path,
#btn-download3 polyline,
#btn-download3 path {
    stroke: #fff;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: all 0.4s ease;
    transition-delay: 0.3s;
}

#btn-download1 path#check,
#btn-download2 path#check,
#btn-download3 path#check {
    stroke-dasharray: 38;
    stroke-dashoffset: 114;
    transition: all 0.4s ease;
}

#btn-download1.downloaded svg .svg-out,
#btn-download2.downloaded svg .svg-out,
#btn-download3.downloaded svg .svg-out {
    opacity: 0;
    animation: drop 0.3s linear;
    transition-delay: 0.4s;
}

#btn-download1.downloaded path#check,
#btn-download2.downloaded path#check,
#btn-download3.downloaded path#check {
    stroke: #20CCA5;
    stroke-dashoffset: 174;
    transition-delay: 0.4s;
}

@keyframes drop {
    20% {
        transform: translate(0, -3px);
    }

    80% {
        transform: translate(0, 2px);
    }

    95% {
        transform: translate(0, 0);
    }
}

@media only screen and (max-width: 768px) {
    .download-client-main {
        width: 90%;
        padding: 10px;
        margin-top: 2rem;
        /* padding-left: 2rem; */
        /* padding-right: 2rem; */
    }

    .download-header {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .download-options {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    .download-button {
        /* width: 50px;
        height: 50px;
        font-size: 16px;
        line-height: 50px;
        margin: 10px 0; */
    }

    .source-info {
        font-size: 12px;
    }
}