* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  font-size: 16px;
  font-family: "Open Sans";
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
  --transition-speed: 600ms;
  --expanded-navbar-size: 16rem;
  --default-navbar-size: 5rem;
}

/* .before-consent-container {
  display: flex !important;
  flex-direction: column !important;
} */


body {
  color: black;
  background-color: white;
  margin: 0;
  /* padding: 0; */
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: #6649b8;
}

.navbar {
  position: fixed;
  background-color: var(--bg-primary);
  transition: width 600ms ease;
  overflow: hidden;
  z-index: 1000;
  /* Ensure it's above other elements */
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.nav-item {
  width: 100%;
}

.nav-item:last-child {
  margin-top: auto;
}

.nav-link {
  display: flex;
  align-items: center;
  height: 5rem;
  color: var(--text-primary);
  text-decoration: none;
  filter: grayscale(100%) opacity(0.7);
  transition: var(--transition-speed);
}


/* .nav-link.notification-highlight {} */

.nav-link:hover {
  filter: grayscale(0%) opacity(1);
  background: var(--bg-secondary);
  color: var(--text-secondary);
}

.link-text {
  display: none;
  margin-left: 2rem;
}

.nav-link svg {
  width: 2rem;
  min-width: 2rem;
  margin: 0 1.5rem;
  margin-right: 0;
}

.fa-primary {
  color: #ff7eee;
}

.fa-secondary {
  color: #df49a6;
}

.fa-primary,
.fa-secondary {
  transition: var(--transition-speed);
}

.logo {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
  color: var(--text-secondary);
  background: var(--bg-secondary);
  font-size: 1.5rem;
  letter-spacing: 0.3ch;
  width: 100%;
}

.logo svg {
  transform: rotate(0deg);
  transition: var(--transition-speed);
}

.logo-text {
  font-size: 0.92rem;
  display: inline;
  position: absolute;
  left: -999px;
  transition: var(--transition-speed);
}

.navbar:hover .logo svg {
  transform: rotate(-180deg);
}

.person {
  margin-left: 5rem;
  padding: 1rem;
}

.smooth_text {
  color: black;
  transition: color 0.3s ease-in-out;
  /* Specify the property to transition and its duration */
}

.fade-in {
  transition: opacity 1s ease;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}


@media only screen and (max-width: 1280px) {
  .navbar {
    bottom: 0;
    width: 100vw;
    height: 5rem;
    position: fixed;
    /* Add this to keep it fixed at the bottom */
    z-index: 1000;
    /* Add this to ensure it stays on top of other elements */
  }

  .logo {
    display: none;
  }

  .navbar-nav {
    flex-direction: row;
  }

  .nav-link {
    justify-content: center;
  }

  main {
    margin-bottom: 5rem;
    /* Add bottom margin to main content equal to navbar height */
  }
}


/* Large screens */
@media only screen and (min-width: 1280px) {
  .navbar {
    top: 0;
    width: 5rem;
    height: 100vh;

  }

  .navbar:hover {
    width: var(--expanded-navbar-size);
  }

  .navbar:hover .link-text {
    display: inline;
  }

  .navbar:hover .logo svg {
    margin-left: 11rem;
  }

  .navbar:hover .logo-text {
    left: 0px;
  }

  main {
    margin-left: 5rem;
    padding: 1rem;
  }

  .shiftContent {
    margin-left: var(--expanded-navbar-size);
    transition: var(--transition-speed) ease;
  }

  .shiftContentBack {
    margin-left: var(--default-navbar-size);
    transition: var(--transition-speed) ease;
  }
}

.dark {
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
}

.light {
  --text-primary: #1f1f1f;
  --text-secondary: #000000;
  --bg-primary: #ffffff;
  --bg-secondary: #e4e4e4;
}

.solar {
  --text-primary: #576e75;
  --text-secondary: #35535c;
  --bg-primary: #fdf6e3;
  --bg-secondary: #f5e5b8;
}

.theme-icon {
  display: none;
}

.dark #darkIcon {
  display: block;
}

.light #lightIcon {
  display: block;
}

.solar #solarIcon {
  display: block;
}

.logout-button {
  padding: 10px 20px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin: 20px;
  /* Adjust as needed */
}

.logout-button:hover {
  background-color: var(--bg-primary);
}