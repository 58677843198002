/* homeStyle.css */

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Cinzel:wght@700&family=Cinzel+Decorative:wght@700&display=swap');

body {
    font-family: 'Noto Sans JP', Arial, sans-serif;
}

.home-card-content {
    color: #e0e0e0;
}

@media (prefers-color-scheme: dark) {
    .collapsible-section {
        background-color: #16213e;
    }

    .home-card-content {
        background-color: rgba(233, 69, 96, 0.1);
        color: #e0e0e0;
    }

    .player-item {
        background-color: rgba(233, 69, 96, 0.08);
    }

    .server-info-container,
    .rankings {
        background: linear-gradient(135deg, #0f3460, #16213e);
    }

    .server-info-title,
    .player-info-title {
        background-color: #e94560;
        color: #1a1a2e;
    }
}


.home-page-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.home-container {
    display: flex;
    flex-direction: row;
    padding: 20px;
    gap: 4rem;
    align-self: center;
}

.title-main-cont {
    align-self: center;
    position: relative;
    width: 100%;
    max-width: 100vw;
    height: auto;
    min-height: 30vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    border: 3px solid #e94560;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(233, 69, 96, 0.3);
    padding: 1rem;
    box-sizing: border-box;

}

/* .title-main-cont {
    align-self: center;
    position: relative;
    width: 3160px;
    height: 450px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    border: 3px solid #e94560;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(233, 69, 96, 0.3);

} */

.background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translateX(-50%) translateY(-50%);
    z-index: 0;
    object-fit: cover;
}

.home-page-title-container {
    margin-top: 1rem;
    position: absolute;
    align-self: flex-start;
    z-index: 2;
    /* top: -0.9em;
    left: 20px; */
    padding: 0 10px;
    /* background: #1a1a2e; */
}

.awakening-text {
    font-family: 'Cinzel Decorative', cursive;
    font-size: 2.8rem;
    color: #e94560;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7),
        0 0 10px rgba(233, 69, 96, 0.5),
        0 0 20px rgba(233, 69, 96, 0.3);
    animation: textGlow 3s ease-in-out infinite;
    margin: 0;
    line-height: 1;
    transition: transform 0.3s ease, text-shadow 0.3s ease;
}

.awakening-text:hover {
    transform: scale(1.05);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8),
        0 0 15px rgba(233, 69, 96, 0.7),
        0 0 30px rgba(233, 69, 96, 0.5),
        0 0 45px rgba(233, 69, 96, 0.3);
}

@keyframes textGlow {

    0%,
    100% {
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7),
            0 0 10px rgba(233, 69, 96, 0.5),
            0 0 20px rgba(233, 69, 96, 0.3);
    }

    50% {
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7),
            0 0 15px rgba(233, 69, 96, 0.7),
            0 0 30px rgba(233, 69, 96, 0.5);
    }
}


/* Ensure the video doesn't overflow on smaller screens */
@media (max-width: 1100px) {
    .title-main-cont {
        width: 90%;
        height: 400px;
    }

    .awakening-text {
        font-size: 1.5rem;
    }
}

@media (max-width: 768px) {
    .title-main-cont {
        height: 300px;
    }

    .awakening-text {
        font-size: 1.2rem;
    }
}

/* Adjust existing styles if necessary */
.home-container {
    margin-top: 2rem;
}

/* If you want to add a gradient overlay to the video for better text visibility */
.title-main-cont::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: linear-gradient(rgba(26, 26, 46, 0.5), rgba(24, 68, 187, 0.5)); */
    z-index: 1;
}

/* Ensure the title text is above the gradient overlay */
.home-page-title-container {
    z-index: 2;
}

.server-info,
.player-info {
    display: flex;
    flex-direction: column;
    order: 1;
}

.server-info-container,
.player-info-container {
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;
}



.rankings,
.player-info-container {
    padding-bottom: 18rem;
}


.server-info-container,
.rankings {
    border-right: 2px solid transparent;
    -webkit-box-shadow: -4px 9px 15px 1px rgba(0, 0, 0, 0.63);
    -moz-box-shadow: -4px 9px 15px 1px rgba(0, 0, 0, 0.63);
    border-radius: 2rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #fff;
    white-space: nowrap;
    transition: background 0.5s ease, transform 0.3s ease;
    border-radius: 1.6rem;
    height: 20rem;
    max-height: 20rem;
    background: linear-gradient(135deg, #0f3460 0%, #16213e 100%);
    border: 2px solid #e94560;
    box-shadow: 0 6px 12px rgba(233, 69, 96, 0.2), 0 3px 6px rgba(233, 69, 96, 0.1);
}

.server-info-container:hover,
.rankings:hover {
    background: linear-gradient(135deg, #0f3460 0%, #09132e 100%);
    transform: scale(1.004);
}

.sv-info-line,
.player-info-line {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.sv-info-line {
    background-color: rgba(255, 255, 255, 0.05);
    margin-bottom: 5px;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding-left: 1rem;
    padding-right: 1rem;
}

.info-name {
    width: 7rem;
}

.info-value {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.server-info-title,
.player-info-title {
    padding: 0.75rem 1.5rem;
    margin: 0.5rem 0;
    text-align: center;
    color: #1C6EA4;
    background-color: #fff;
    border: none;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
    font-family: 'SpaceGrotesk-Light', sans-serif;
    font-size: 1.5rem;
    font-weight: 500;
}

.server-info-title:hover,
.player-info-title:hover {
    color: #fdfdfd;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
}

.rankings {
    order: 3;
}

.announcement-container {
    order: 2;
    border-radius: 8px;
    width: 40rem;
    overflow-y: scroll;
}

.announcement-container h2 {
    margin-top: 2.5rem;
}

.announcement-container ul {
    list-style-type: disc;
    padding-left: 1rem;
    margin: 0;
}

.announcement-container a {
    color: #e94560;
}

.announcement-container a:hover {
    color: #533483;
}

.collapsible-section {
    margin-bottom: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: box-shadow 0.3s ease;
    width: 40rem;
}

.collapsible-section.expanded {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}




.collapsible-title {
    padding: 15px 25px;
    margin: 0;
    font-size: 2rem;
    color: #fff;
    line-height: 1.3;
    font-weight: 500;
    background: linear-gradient(100deg, #e94560, #0f3460);
    font-family: 'Cinzel', serif;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 4px 8px rgba(233, 69, 96, 0.2);
    transition: background 0.3s ease, color 0.3s ease, transform 0.3s ease;
    user-select: none;
    cursor: pointer;
    text-align: center;
    width: 100%;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.collapsible-title:hover {
    background: linear-gradient(100deg, #e94560, #15062c);
    color: #fff;
    transform: scale(1.01);
}

.collapsible-section.expanded .collapsible-title {
    background-color: rgba(0, 102, 204, 0.9);
    color: white;
}

.collapsible-content {
    background-color: rgba(15, 52, 96, 0.8);
    overflow: hidden;
}

.home-cards-li {
    margin-left: 1rem;
}

.home-card-content {
    /* list-style-type: none; */
    /* display: inline-block; */
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    /* list-style-type: disc; */
    border-bottom: 1px solid #cccccc9d;
    font-family: 'Arial Rounded MT Bold', 'Helvetica Rounded', Arial, sans-serif;
    padding-bottom: 0.2rem;
    margin-bottom: 10px;
    font-size: 16px;
    color: #cfcbcb;
    width: 100%;
}

.home-cards-parent {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    list-style-type: none;
    margin: 0;
}

.empty-card {
    height: 200px;
    width: 100%;
    margin-bottom: 1rem;
}

.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.loading-spinner {
    margin-top: 10rem;
    align-self: center;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #ff7e5f;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.error-message {
    color: #ff0000;
    text-align: center;
    padding: 10px;
}

/* Player Rankings Styles */
.player-rankings-container {
    width: 100%;
    max-height: 300px;
}

.player-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.player-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.player-rank {
    color: #e94560;
}

.player-name {
    flex-grow: 1;
    margin: 0 10px;
}

.player-level {
    color: #dbd9e7;
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.pagination button {
    background-color: #342946;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination button:hover:not(:disabled) {
    background-color: #feb47b;
}

.pagination button:disabled {
    background-color: #1f1e1e;
    cursor: not-allowed;
}

/* New styles for editable components */
.collapsible-title-container {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.editable-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.editable-title button,
.editable-content button {
    background-color: #342946;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 10px;
    font-size: 12px;
}

.editable-title button:hover,
.editable-content button:hover {
    background-color: #feb47b;
}

.editable-title input,
.editable-content textarea {
    width: calc(100% - 20px);
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #cfcbcb;
    background-color: rgb(51, 53, 64);
}

.editable-content textarea {
    min-height: 100px;
    resize: vertical;
    margin-top: 5px;
}

.editable-content {
    margin-bottom: 10px;
    width: 100%;
}

.collapsible-section .collapsible-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
}

.collapsible-section .collapsible-title::after {
    content: '';
    display: inline-block;
    width: 0.3em;
    height: 0.3em;
    border-right: 2px solid currentColor;
    border-bottom: 2px solid currentColor;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
    margin-left: 0.5em;
    position: relative;
    top: -0.2em;
}

.collapsible-section.expanded .collapsible-title::after {
    transform: rotate(225deg);
}

.add-section-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
}

.add-section-btn:hover {
    background-color: #45a049;
}

.delete-section-btn {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.delete-section-btn:hover {
    background-color: #d32f2f;
}

.delete-confirm {
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    color: #721c24;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
}

.delete-confirm p {
    margin-bottom: 10px;
}

.delete-confirm button {
    margin-right: 10px;
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
}

.delete-confirm button:first-of-type {
    background-color: #dc3545;
    color: white;
}

.delete-confirm button:last-of-type {
    background-color: #6c757d;
    color: white;
}

@media screen and (max-width: 1300px) {
    .home-container {
        display: flex;
        flex-direction: column;
    }

    .empty-card {
        width: 1rem;
    }

    .announcement-container {
        height: 43rem;
        overflow-y: scroll;
    }

    .server-info {
        align-self: center;
    }
}

.add-content-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

.add-content-btn:hover {
    background-color: #45a049;
}

.delete-content-btn {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.delete-content-btn:hover {
    background-color: #d32f2f;
}

.content-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}

.collapsible-section {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
}

.collapsible-section:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.1);
}



.home-card-content {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 10px;
    border-radius: 5px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}


.server-info-title,
.player-info-title {
    background-color: #e94560;
    color: #1a1a2e;
    font-family: 'Cinzel', serif;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3);
}

.player-item {
    background-color: rgba(233, 69, 96, 0.1);
    border-bottom: 1px solid rgba(233, 69, 96, 0.3);
}

.player-rank {
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.add-section-btn,
.add-content-btn,
.pagination button {
    background-color: #533483;
    color: #ffffff;
    border: 1px solid #e94560;
}

.add-section-btn:hover,
.add-content-btn:hover,
.pagination button:hover:not(:disabled) {
    background-color: #e94560;
    color: #1a1a2e;
}

/* Adjust contrast for better readability */
.home-card-content {
    color: #f0f0f0;
}

.player-name {
    color: #ffffff;
}

.player-level {
    color: #a5b1c2;
}

/* Dark mode adjustments */
@media (prefers-color-scheme: dark) {
    .collapsible-section {
        background-color: #2a2a2a;
    }

    .home-card-content {
        background-color: rgba(255, 255, 255, 0.05);
        color: #f0f0f0;
    }

    .player-item {
        background-color: rgba(255, 255, 255, 0.08);
    }

    /* .server-info-container,
    .rankings {
        background: linear-gradient(135deg, #3a3a3a, #2a2a2a);
    } */





    .server-info-title,
    .player-info-title {
        background-color: #4a4a4a;
        color: #ffffff;
    }
}