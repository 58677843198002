/* Cookie Consent Bar Styles */
.cookie-consent-bar {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


.cookie-consent-button {
  background-color: #007bff;
  border-radius: 5px;
}

.cookie-consent-decline-button {
  background-color: #6c757d;
  border-radius: 5px;
}

/* Ensure buttons are of the same size */
.cookie-consent-button,
.cookie-consent-decline-button {
  min-width: 120px;
  /* Adjust based on content */
  text-align: center;
}

/* Hover effects for buttons */
.cookie-consent-button:hover {
  background-color: #0056b3;
}

.cookie-consent-decline-button:hover {
  background-color: #545b62;
}


@media (min-width: 768px) {
  .cookie-container {
    padding-left: 4.5rem;
    display: flex;
  }
}


.CookieConsent {
  background: linear-gradient(to right, rgba(0, 0, 255, 0.2), rgba(191, 7, 7, 0.2)) !important;
  border: 2px solid rgba(0, 0, 255, 0.357) !important;
  color: blue !important;
  width: 63rem !important;
  height: 5rem !important;
  position: fixed !important;
  top: 0.5rem !important;
  left: calc(50% - 31.5rem) !important;
  /* Half of width (63rem) */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 0.5rem !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important;
}


.cookie-consent-decline-button {
  font-size: 16px !important;
  /* font-weight: 200 !important; */
  letter-spacing: 1px !important;
  /* padding: 13px 20px 13px !important; */
  outline: 0 !important;
  border: 1px solid black !important;
  cursor: pointer !important;
  position: relative !important;
  background-color: rgba(0, 0, 0, 0) !important;
  user-select: none !important;
  -webkit-user-select: none !important;
  touch-action: manipulation !important;
}

.cookie-consent-decline-button:after {
  content: "" !important;
  background-color: #0e29f9 !important;
  width: 100% !important;
  z-index: -1 !important;
  position: absolute !important;
  height: 100% !important;
  top: 7px !important;
  left: 7px !important;
  transition: 0.2s !important;
}

.cookie-consent-decline-button:hover:after {
  top: 0px !important;
  left: 0px !important;
}

@media (min-width: 768px) {
  .cookie-consent-decline-button {
    padding: 8px 12px 8px !important;
  }
}

.cookie-consent-button {
  font-size: 16px !important;
  /* font-weight: 200 !important; */
  letter-spacing: 1px !important;
  /* padding: 13px 20px 13px !important; */
  outline: 0 !important;
  border: 1px solid black !important;
  cursor: pointer !important;
  position: relative !important;
  background-color: rgba(0, 0, 0, 0) !important;
  user-select: none !important;
  -webkit-user-select: none !important;
  touch-action: manipulation !important;
}

.cookie-consent-button:after {
  content: "" !important;
  background-color: #099a3c !important;
  width: 100% !important;
  z-index: -1 !important;
  position: absolute !important;
  height: 100% !important;
  top: 7px !important;
  left: 7px !important;
  transition: 0.2s !important;
}

.cookie-consent-button:hover:after {
  top: 0px !important;
  left: 0px !important;
}

.cookie-policy-link {
  text-decoration: none !important;
  cursor: pointer !important;
  color: black !important;
  padding-left: 5px !important;
}

.cookie-policy-link:hover {
  text-decoration: underline !important;
}



@media (max-width: 768px) {
  .cookie-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 90%;
    padding-left: 1rem;
  }

  .CookieConsent {
    background: linear-gradient(to right, rgba(0, 0, 255, 0.2), rgba(191, 7, 7, 0.2)) !important;
    border: 2px solid rgba(0, 0, 255, 0.357) !important;
    color: blue !important;
    width: 18rem !important;
    height: 20rem !important;
    position: fixed !important;
    top: 7.5rem !important;
    left: 1.7rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 0.5rem !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important;
    display: flex;
    flex-direction: row;
  }
}