@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

/* Overlay for the entire page */
.overlay-signin {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.signin-signup-modal {
  background-color: rgba(14, 23, 192, 0.3);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 30px 40px;

  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 23.4rem;
  height: 33rem;
  padding: 20px;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.signin-signup-modal h1 {
  color: #fff;
  margin-bottom: 4rem;
}

.signin-signup-modal.signup {
  width: 22rem;
  height: 40rem;
}

.signin-signup-modal.reset {
  width: 26rem;
  height: 38rem;
}

.form-content {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  width: 100%;
  /* Adjust width as necessary */
  min-height: auto;
  overflow: visible;
  /* Allows content to extend beyond */
}

.input-box {
  position: relative;
  display: flex;
  align-items: center;
  /* margin-top: 1.2rem; */
  flex-direction: column;
  /* gap: 0.5rem; */
}

.input-box.reset {
  gap: 1rem;
}

.input-box input:hover {
  /* Futuristic hover effect */
  background-color: rgba(27, 0, 73, 0.1);
  /* border-color: #00ff00; */
  box-shadow: 0 0 10px rgba(14, 23, 192, 0.5);
  transform: scale(1.02);
  /* Slightly larger */
  transition: all 0.3s ease;
  /* Smooth transition for all changes */
}

.input-box input:not(:hover) {
  /* Effect when not hovered */
  background-color: transparent;
  border-color: #fff;
  box-shadow: none;
  transform: scale(1);
  /* Return to original size */
  transition: all 0.3s ease;
  /* Smooth transition for all changes */
}

.icon-login {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.icon-reset-new {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-210%);
}

.icon-reset-current {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(90%);
}

.input-box input {
  font-family: "Poppins", sans-serif;
  padding: 10px 10px 10px 30px;
  border-radius: 5px;
  border: 2px solid #fff;
  background-color: transparent;
  color: white;
  outline: none;
  width: 100%;
  max-width: 100%;
  /* Set maximum width */
}

.submitbtn,
.reset-code-btn {
  font-family: "Poppins", sans-serif;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: white;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  transition: all 0.5s ease-in-out;
  max-width: 100%;
  /* Set maximum width */
}

.form-control {
  background-color: #000;
}

.submitbtn:hover {
  opacity: 0.9;
  background-color: rgba(26, 0, 131, 0.3);
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
}

@media (max-width: 600px) {
  .signin-signup-modal {
    width: 90%;
  }
}

.close-button {
  position: absolute;
  top: 0px;
  right: 10px;
  background: none;
  color: white;
  font-size: 25px;
  cursor: pointer;
  padding: 0 14px 0 14px;
  z-index: 1001;
  /* Ensure the close button is above the modal content */
}

.account-actions {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Center horizontally within the parent container */
  align-items: center;
  /* Center vertically within the parent container */
}

.login-header {
  align-self: center;
}

.signup-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* Center horizontally within the parent container */
  align-items: center;
  /* Center vertically within the parent container */
  gap: 6px;
}

.forgot-pass,
.back-to-signin {
  color: #fff;
  text-decoration: none;
}

.forgot-pass:hover,
.back-to-signin:hover {
  text-decoration: underline;
}

.signup-box {
  font-size: 14px;
  text-align: center;
}

.signup-box a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

.signup-box a:hover {
  text-decoration: underline;
}

.checkbox-container {
  display: flex;
  align-items: center;
  /* Align items vertically */
  user-select: none;
}

/* Style for the futuristic checkbox */
.futuristic-checkbox {
  -webkit-appearance: none;
  /* Remove default styles for webkit browsers */
  -moz-appearance: none;
  /* Remove default styles for Firefox */
  appearance: none;
  /* Remove default styles for other browsers */

  width: 0.8rem;
  /* Adjust the size of the checkbox */
  height: 0.8rem;
  /* Adjust the size of the checkbox */
  border: 2px solid #fff;
  /* Border color */
  background-color: transparent;
  /* Transparent background */
  border-radius: 5px;
  /* Rounded corners */
  position: relative;
  cursor: pointer;
}

/* Style for the checkbox when checked */
.futuristic-checkbox:checked::before {
  content: "✔";
  /* Checkmark symbol */
  font-size: 0.55rem;
  /* Adjust the checkmark size */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  /* Checkmark color */
}

/* Style for the checkbox label text */
.futuristic-checkbox+label {
  color: #fff;
  margin-left: 1px;
  font-size: 0.8rem;
}

/* Style for the checkbox label text when hovering */
.futuristic-checkbox:hover+label {
  text-decoration: underline;
  /* Add underline on hover */
  cursor: pointer;
  /* Change cursor on hover */
}

.checkbox-label {
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.checkbox-label:hover {
  text-decoration: underline;
}

.back-to-signin {
  margin-top: 2rem;
  align-self: center;
  font-size: 0.9rem;
}

.reset-pass-box {
  display: flex;
  gap: 0.5rem;
}

.singup-box {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.submitbtn {
  transform: translateY(30%);
}

.success-message {
  color: green;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-top: 30px;
  /* Increased margin-top from 20px to 30px */
  animation: bounce 1s ease;
}

.success-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: green;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  ::placeholder {
    color: white;
    opacity: 1;
    /* Ensures the color is fully applied */

  }
}


.error-container {
  padding-top: 0.2rem;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  color: red;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 1rem;
  font-size: 1rem;
}


.error-icon {
  display: flex;
  /* Use flex to keep the icon aligned */
  margin-right: 10px;
  /* Space between icon and message */
  font-size: 1.3rem;
  /* Icon size */
  flex-shrink: 0;
  /* Prevent the icon from shrinking */
  color: red;
  /* Icon color, assuming you want it the same as the error message */
}

.signin-error-message,
.signinup-error-message,
.signinup-error-message {
  background: linear-gradient(to right, white, #d81111);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 0.8rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: calc(100% - 1.3em - 10px);
  /* Adjusted to take the icon width and margin into account */
  box-sizing: border-box;
  animation: fadeIn 1s;
  font-weight: 400;
  /* text-decoration: underline; */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.input-box.error_field_highlight input {
  /* Apply the style to the input element inside the .input-box with the .highlight_email class */
  border-color: red;
  /* Change the border color to red */
  background-color: rgba(255,
      0,
      0,
      0.1);
  /* Change the background color to a light red */
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
  /* Add a red box shadow */
  transition: all 0.3s ease;
  /* Apply a smooth transition */
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.email-not-activated-container {
  margin-top: 2.2rem;
  background-color: #FFA500;
  /* Orange background */
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  text-align: center;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);


  /* background: rgb(216, 39, 39); */
  /* border-radius: 50%; */

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}


@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  30% {
    transform: scale(0.98);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.98);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.signin-error-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  /* Semi-transparent black background */
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}