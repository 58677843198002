.nav-item.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    position: relative;
    transition: opacity 0.3s ease, background-color 0.3s ease;
}

.nav-item.disabled .nav-link {
    pointer-events: none;
    color: #888;
}

.nav-item.disabled svg {
    fill: #888;
}

.nav-item .tooltip {
    visibility: hidden;
    width: 220px;
    background-color: rgb(255, 255, 255);
    color: #fd0000;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 80%;
    left: 50%;
    margin-left: -110px;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
}

.nav-item .tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(255, 255, 255) transparent transparent transparent;
}

.nav-item.disabled:hover {
    opacity: 0.7;
    background-color: rgba(78, 78, 78, 0.5);
}

.nav-item.disabled:hover .tooltip {
    visibility: visible;
    opacity: 1;
}


/* Ensure the notification doesn't overflow on small screens */
/* @media screen and (max-width: 600px) {
    .notification-popup {
        right: 2px;
        top: 2px;
        min-width: 16px;
        height: 16px;
        font-size: 10px;
    }
} */

/* .icon-container {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
} */

.nav-icon-container {
    display: flex;
    flex-direction: row;
    /* gap: 0.3rem; */


}

/* .nav-item-container {
    display: flex;
    flex-direction: row;
} */

/* .notification-popup {
    align-items: flex-start;
} */

.notification-popup-container {
    align-self: center;
    margin-bottom: 2.8rem;
    background: rgb(216, 39, 39);
    border-radius: 50%;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;

}

.notification-popup {

    color: white;
    border-radius: 50%;
    min-width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: bold;
    padding: 2px;
    box-sizing: border-box;
    border: 1px solid white;
    box-shadow: 0 0 3px rgba(184, 7, 7, 0.3);
    background-color: rgb(87, 29, 29);

    /* margin: 0 1.5rem 0 1.5rem; */
    /* padding: 0; */
    /* min-width: 1rem;
    min-height: 1rem;
    height: 4rem;
    width: 4rem; */
}

/* .notification-popup {
    transform: translateX(-1.7rem) translateY(-0.8rem);
    background-color: rgba(236, 62, 62);
    color: white;
    border-radius: 50%;
    min-width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    font-weight: bold;
    padding: 2px;
    box-sizing: border-box;
    border: 1px solid white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
} */



@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    50% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    90% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.nav-link {
    position: relative;
}

.notification-hover-message {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    margin-bottom: 10px;
}

.notification-hover-message::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.nav-link:hover .notification-hover-message {
    visibility: visible;
    opacity: 1;
}

.nav-icon-discord {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    transform: translateX(-8%) !important;
}